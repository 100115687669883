import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import { AxiosError } from 'axios';

import {updateCompany} from '../../http/companyAPI';

interface ModalEditCompanyProps {
    show: boolean;
    onHide: () => void;
};

interface IDepartment {
    id: number;
    department: string;
};


const ModalEditCompany: React.FC<ModalEditCompanyProps> = ({show, onHide}) => {
    const [description, setDescription] = useState<string>('ООО «Инженерное бюро Цаммит» успешно выполняет в Москве и в российских регионах полный комплекс работ по проектированию инженерных систем зданий и сооружений, включая все проектные стадии, а также генеральное проектирование индустриальных объектов. Коллектив квалифицированных специалистов нашей фирмы имеет большой опыт выполнения сложных инновационных проектов. Наши сотрудники постоянно совершенствуют свои знания, участвуя в современных разработках и используя новейшие технические решения. Начиная с 2005 года наши специалисты разработали более 100 проектов.');    
    const [department, setDepartment] = useState<IDepartment[]>([
        {
            id: 1,
            department: '...'
        },
    ]);

    const editCompany = () => {
        if (!description.trim()) {
            return alert('Описание компании обязательно для заполнения');
        } else if (department.length === 0) {
            return alert('Необходимо задать хотя бы 1 отделение');
        }

        try {
            const formData = new FormData();
            formData.append('description', description);
            formData.append('department', JSON.stringify(department));

            updateCompany(1, formData).then(() => {
                onHide();
            });
        } catch(err: unknown) {
            const error = err as AxiosError;
            alert(JSON.parse(error.request.response).message);
        }
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изменить данные о компании
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control as="textarea"
                            type="text" 
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            placeholder="Введите новое описание" 
                            maxLength={1200}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-primary"} onClick={editCompany}>Обновить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEditCompany;